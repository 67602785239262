<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @LastEditTime: 2024-07-22 10:51:47
 * @FilePath: /saas-pc/src/plugin/finance-reconciliation/view/report_module/goodsInventoryDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      :isShowToast="false"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />

    <Table
      :emptyText="emptyTableText"
      :data="tableData"
      :isPagination="true"
      :columns="columns"
      :total="totalRecords"
      :model="model"
      :getTableData="reportGoodsSell"
    />
  </div>
</template>

<script>
import commonApi from '@/api'
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../../component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import api from '../../service/index'
import { downLoad } from '../../../../lin/util/util'
import { ElMessage } from 'element-plus'

export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        startTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        // skuName: '',
      },
      tableData: [],
      totalRecords: 0,
      totalPage: 0,
      columns: [
        {
          prop: 'storeCode',
          label: '门店编号',
          width: '100px',
        },
        {
          prop: 'storeName',
          label: '门店名称',
          width: '150px',
        },
        {
          prop: 'batchCode',
          label: '盘点单据号',
          width: '200px',
        },
        {
          prop: 'houseName',
          label: '仓库',
          width: '100px',
        },
        {
          prop: 'categoryName',
          label: '商品类型',
          width: '150px',
        },
        {
          prop: 'productName',
          label: '商品名称',
          width: '200px',
        },

        {
          prop: 'unit',
          label: '单位',
          width: '100px',
        },
        {
          prop: 'spec',
          label: '规格',
          width: '120px',
        },
        {
          prop: 'price',
          label: '成本单价(元)',
          width: '120px',
        },
        {
          prop: 'stock',
          label: '库存数量',
          width: '120px',
        },
        {
          prop: 'inventory',
          label: '盘点数量',
          width: '120px',
        },
        {
          prop: 'checkPrice',
          label: '盘点金额',
          width: '140px',
        },
        {
          prop: 'profitCount',
          label: '盈亏数量',
          width: '140px',
        },
        {
          prop: 'profitFree',
          label: '盈亏金额',
          width: '140px',
        },
        {
          prop: 'agentName',
          label: '经手人',
          width: '140px',
        },
        {
          prop: 'userName',
          label: '操作人',
          width: '140px',
        },
        {
          prop: 'createTime',
          label: '盘点时间',
          width: '200px',
        },
        {
          prop: 'description',
          label: '备注信息',
          width: '160px',
        },
      ],
      searchform: [
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'children',
            multiple: true,
          },
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodeList',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        {
          label: '盘点仓库',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'houseName',
          placeholder: '请选择盘点仓库',
          options: [
            {
              label: '总仓库',
              value: '总仓库',
            },
            {
              label: '超市仓库',
              value: '超市仓',
            },
            {
              label: '存酒仓库',
              value: '存酒仓库',
            },
          ],
        },
        { label: '盘点单据号', type: 'input', prop: 'batchCode', placeholder: '请输入盘点单据号' },

        {
          label: '商品类型',
          placeholder: '请选择商品分类',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'goodsCategory',
          options: [],
          filterable: true,
        },

        { label: '商品', type: 'input', prop: 'goodsName', placeholder: '请输入商品名称' },
        { label: '操作人', type: 'input', prop: 'userName', placeholder: '请输入操作人' },
        { label: '经手人', type: 'input', prop: 'agentName', placeholder: '请输入经手人' },
        {
          label: '日期',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        { label: '导出', type: 'exportButton', url: '/report/export/exportStockGoodsCheckDetail', auth: 'export' },
      ],
    }
  },
  async mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      await this.getStoreList()
      // this.reportGoodsSell(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    async changeArea(areas) {
      let params = {}
      if (areas.length > 0) {
        params.withInOrgans = areas
      }
      let res = await commonApi.getNewShop(params)
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    reportGoodsSell(model) {
      if (!this.getDays(model.startTime, model.endTime)) {
        return
      }
      this.$refs.search.fullscreenLoading = true
      api
        .queryStockGoodsCheckDetail(model)
        .then(res => {
          this.tableData = res.data.records || []
          this.totalRecords = res.data.totalRecords
          this.totalPage = res.data.totalPage
          this.setEmptyDataText()
        })
        .finally(() => {
          this.$refs.search.fullscreenLoading = false
        })
    },
    async getStoreList() {
      let res = await commonApi.getNewShop()
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
      const getTreeData = data => {
        for (var i = 0; i < data.length; i++) {
          if (data[i].children.length < 1) {
            // children若为空数组，则将children设为undefined
            data[i].children = undefined
          } else {
            // children若不为空数组，则继续 递归调用 本方法
            getTreeData(data[i].children)
          }
        }
        return data
      }
      commonApi.getNewArea().then(res => {
        this.searchform[0].options = getTreeData(res.data)
      })
      api
        .selectByType({
          pageSize: 100,
          current: 1,
          type: 1,
        })
        .then(res => {
          this.searchform[4].options = res.data.records.map(item => {
            return {
              value: item.code,
              label: item.name,
            }
          })
        })

      api.getSelectList().then(res => {
        if (res.code === '200') {
          this.searchform[3].options = res.data.map(item => {
            return {
              value: item.code,
              label: item.name,
            }
          })
        } else {
          ElMessage.error(res.msg)
        }
      })
    },
    parentSearch(param) {
      const {
        goodsName,
        goodsCategory,
        storeCodeList,
        regionCodeList,
        houseName,
        userName,
        agentName,
        batchCode,
      } = param
      this.model = {
        goodsName,
        goodsCategory,
        storeCodeList,
        regionCodeList,
        houseName,
        userName,
        agentName,
        batchCode,
      }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.type = 1
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.reportGoodsSell(this.model)
    },

    async exportReport(param) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const {
        goodsName,
        goodsCategory,
        storeCodeList,
        regionCodeList,
        houseName,
        userName,
        agentName,
        batchCode,
      } = param
      this.model = {
        goodsName,
        goodsCategory,
        storeCodeList,
        regionCodeList,
        houseName,
        userName,
        agentName,
        batchCode,
      }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.type = 1
      this.model.pageSize = 10
      if (!this.getDays(this.model.startTime, this.model.endTime)) {
        this.$refs.search.fullscreenLoading = false
        return
      }
      let url = this.searchform.find(item => item.label === '导出').url
      const res = await api.exportReport({ url, data: this.model })
      this.$refs.search.fullscreenLoading = false
      const reader = new FileReader()
      reader.readAsText(res.data, 'utf-8')
      reader.onload = function() {
        try {
          const t = JSON.parse(reader.result) // 这里就得到了json
          ElMessage({
            message: t.msg,
            type: 'error',
          })
        } catch (error) {
          downLoad(res, '商品盘点明细.xlsx')
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100vh - 100px) !important;
}
</style>
